import React from 'react';

function ScrollTopButton() {
  return(
    <button className="sm:animate-bounce transform text-lightsaberlight hover:animate-none hover:-translate-y-4 hover:scale-105 focus:outline-none transition ease-in duration-300 animate-fadeIn">
      <svg className="h-8 md:h-10 fill-current" viewBox="-21 0 512 512.39734" xmlns="http://www.w3.org/2000/svg">
        <path d="m234.867188 512.398438c-129.386719 0-234.667969-105.28125-234.667969-234.667969 0-88.339844 48.746093-168.171875 127.210937-208.339844 7.933594-4.074219 17.515625-.898437 21.546875 6.953125 4.007813 7.871094.894531 17.515625-6.957031 21.527344-67.730469 34.664062-109.800781 103.59375-109.800781 179.859375 0 111.746093 90.921875 202.667969 202.667969 202.667969 111.742187 0 202.664062-90.921876 202.664062-202.667969 0-76.136719-42.003906-145.066407-109.632812-179.859375-7.847657-4.054688-10.941407-13.699219-6.890626-21.546875 4.035157-7.832031 13.65625-10.945313 21.570313-6.914063 78.3125 40.320313 126.953125 120.148438 126.953125 208.320313 0 129.40625-105.277344 234.667969-234.664062 234.667969zm0 0"/>
        <path d="m132.550781 282.210938c-6.996093 0-13.910156-2.710938-19.136719-7.9375-7.957031-7.957032-10.175781-20.117188-5.589843-30.269532l102.292969-228.203125c.917968-2.496093 2.945312-5.527343 5.65625-8.214843 10.089843-10.089844 28.050781-10.152344 38.269531.042968 2.753906 2.753906 4.738281 5.78125 5.953125 9.023438l101.953125 227.394531c4.585937 10.152344 2.324219 22.3125-5.589844 30.226563-8.320313 8.34375-20.972656 10.328124-31.488281 4.972656l-90.027344-45.589844-90.046875 45.609375c-3.863281 1.984375-8.085937 2.945313-12.246094 2.945313zm102.316407-82.496094c2.472656 0 4.949218.574218 7.230468 1.726562l84.96875 43.03125-92.199218-205.71875-92.183594 205.71875 84.949218-43.03125c2.28125-1.152344 4.757813-1.726562 7.234376-1.726562zm-4.4375-170.835938c.039062.082032.082031.191406.125.277344zm9.257812-.855468-.386719.832031c.128907-.253907.257813-.53125.386719-.832031zm0 0"/>
      </svg>
    </button>
  );
}

export default ScrollTopButton;